<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div>
          <div class="content-titles-and-links display-flex align-items-center">
            <p class="text-title mon-bold">{{ texts.home.purchaseOrder.textTitle }} 📥</p>
            <v-spacer></v-spacer>
            <div class="display-flex align-items-center">
              <p
                @click="redirectOrders"
                class="text-link cursor-pointer text-underline mon-medium"
              >{{ texts.home.purchaseOrder.textAllOrders }}</p>
              <v-icon
                color="#0071FF"
                size="18px"
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <div
            v-if="aOrders.length > 0"
            class="scroll-horizontal display-flex align-items-center"
          >
            <div class="display-flex align-items-center">
              <div
                v-for="(item, index) in aOrders"
                :key="index"
                style="position: relative;"
                class="container-card mr-5"
                @click="redirectOrderDetail(item.sPurchaseOrderId, item.sPurchaseOrderStatusId)"
              >
                <div
                  class="label-confirm-order mon-medium"
                  v-if="item.sPurchaseOrderStatusId == 'dac89b64-079a-4c71-b894-49436e0b61d5'"
                >
                  {{ item.sPurchaseOrderStatusName }}
                </div>
                <div class="display-flex align-items-center">
                  <div class="display-flex align-items-center">
                    <p class="text-name-market mon-regular">{{ item.sCustomerEnterpriseName }}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="display-flex align-items-center">
                    <div class="display-flex align-items-center justify-content-center date-article mon-regular">
                      {{ item.created_at }}
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="text-purchase-order mon-regular">Orden de compra</p>
                  <p class="text-numer-purchase-order mon-bold">{{ item.sFolioId }}</p>
                </div>
                <div class="mt-2">
                  <p class="text-price mon-regular">${{ formatMoney(item.dTotalPrice) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="empty-content display-flex align-items-center justify-content-center">
              <div>
                <!-- <div class="display-flex align-items-center justify-content-center">
                  <img
                    class="empty-image"
                    src="@/assets/images/oops.png"
                    alt=""
                  >
                </div> -->
                <p class="empty-text mon-regular">{{ texts.home.purchaseOrder.empty.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomePurchaseOrderLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aOrders: [],
      lengthPage: 0,
      iCurrentPage: 1,
    };
  },
  beforeMount() {
    this.getOrders();
  },
  methods: {
    redirectOrderDetail: function (id, status) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          type: "in-process",
          id,
          status,
        },
      });
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    formatDate: function (date) {
      var formating_date = MOMENT(date).format("MMM. DD, YYYY");
      return formating_date;
    },
    redirectOrders: function () {
      this.$router.push("/orders");
    },
    getOrders: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/purchase-orders`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
        },
      })
        .then((response) => {
          this.aOrders = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getOrders();
      }
    },
  },
};
</script>

<style scoped>
.text-link {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-underline:hover {
  text-decoration: underline;
}

.scroll-horizontal {
  width: 100%;
  overflow-x: auto;
  padding: 10px 0px;
}

.text-price {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-purchase-order {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
}

.text-numer-purchase-order {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
}

.label-confirm-order {
  background: transparent linear-gradient(10deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box;
  width: fit-content;
  padding: 2px 10px;
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

.container-card {
  background-color: #293c4d;
  padding: 20px 15px;
  border-radius: 10px;
  width: 340px !important;
}

.container-card:hover {
  cursor: pointer;
  opacity: 0.8;
}

.text-name-market {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-article {
  background: #b2b8be 0% 0% no-repeat padding-box;
  border-radius: 11px;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #304458;
  opacity: 1;
  padding: 5px;
}

.content-image {
  width: 29px;
  height: 29px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.text-title {
  text-align: left;
  font-size: 21px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
}

@media (max-width: 600px) {
  .text-title {
    font-size: 20px;
    width: 100%;
  }
  .content-titles-and-links {
    display: block;
    margin-bottom: 10px;
  }
}
</style>